<template>
  <b-form-group :invalid-feedback="failed" :label="title" :state="state">
    <v-select
      :disabled="readOnly"
      :id="fieldId"
      :options="options"
      v-model="currentCode"
      label="value"
      :state="state"
      :reduce="item => item.key"
      :clearable="true"
      :searchable="true"
    >
    </v-select>
  </b-form-group>
</template>
<script>
export default {
  name: 'FieldSelect',
  model: {
    prop: 'code',
    event: 'input',
  },
  props: {
    fieldId: String,
    options: Array,
    title: String,
    code: String,
    state: Boolean,
    errorMessage: String,
    readOnly: Boolean,
    hasCustomEvent: Boolean,
    customEvent: Function,
  },
  // props: ['code', 'options', 'title'],
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    currentCode: {
      get() {
        return this.code;
      },
      set(code) {
        // console.log('updateSelf', code);
        this.$emit('input', code);
      },
    },
  },
};
</script>

<style scoped>

</style>
